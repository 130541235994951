import Vue from 'vue';
import router from '@/router/index.js';
import store from '@/store/index.js'

export function createdHeaderOptions(component, props) {
    const vm = new Vue({
        router,
        store,
        render(r) {
            return r(component, {props})
        }
    });
    vm.$mount()
    document.body.appendChild(vm.$el);
    const comp = vm.$children[0];
    comp.close = () => {
        vm.$destroy();
        document.getElementById('J_header-option') && vm.$el && document.body.removeChild(vm.$el)
    };

    return comp;
} 