<template>
    <div 
        class="header-menus" 
        @mouseenter="headerMenuMouseenter"
        @mouseleave="headerMenuMouseleave"
    >
        <span class="ears left" v-show="showEars && hoverShow" @click="goLeft">
            <img :src="earsLeft" alt="earsLeft">
        </span>
        <ul class="header-list">
            <el-scrollbar
                ref="scroll"
                @wheel.native.prevent="changeScroll"
                wrap-style="overflow-y:hidden;"
                style="display:flex;justify-content:center;"
            >
                <li
                    v-for="item of headerMenus"
                    :key="item.id"
                    :class="['list-item', {current: item.mark}]"
                    @click="changeModulePage(item)"
                >
                    {{ item.title }}
                </li>
            </el-scrollbar>
        </ul>
        <span class="ears right" v-show="showEars && hoverShow" @click="goRight">
            <img :src="earsRight" alt="earsRight">
        </span>
    </div>
</template>

<script>
export default {
    name: "HeaderMenu",
    props: {
        headerMenus: {
            type: Array,
        },
        hoverColor: {
            type: String,
        },
    },
    data() {
        return {
            showEars: false,
            hoverShow: false,
            sLeft: 0
        }
    },
    created() {
        this.$on('globalResize', () => {
            this.setShowEars()
        })
    },
    mounted() {
        this.setStyle();
        this.setShowEars();
    },
    computed: {
        curMenItem() {
            return this.$route.matched[0].name;
        },
        headerMenusWidth () {
            let len = this.headerMenus.length;
            return len * 112;
        },
        earsLeft() {
            return require('@/assets/images/header/menu-left.png')
        },
        earsRight() {
            return require('@/assets/images/header/menu-right.png')
        }
    },
    methods: {
        changeScroll(e) {
            let wrap = this.$refs.scroll.$refs.wrap;
            wrap.scrollLeft = wrap.scrollLeft - e.wheelDelta;
        },
        setStyle() {
            this.$nextTick(() => {
                this.$el.style.setProperty("--hoverColor", this.hoverColor);
            });
        },
        changeModulePage(data) {
            const _this = this;
            this.headerMenus.map(item => {
                this.$set(item, 'mark', false);
            })
            this.headerMenus.map(item => {
                if (data.menuId == item.menuId) {
                    this.$set(item, 'mark', true);
                }
            })
            setTimeout(_this.$eventDispatch("changeModulePage", data));
        },
        setHeaderMenuSel (curRouterdata) {
            this.headerMenus.map(item => {
                this.$set(item, 'mark', false);
            })

            this.headerMenus.map(item => {

                if (item.name === curRouterdata.name) {
                    this.$set(item, 'mark', true);
                }

                if (item.children && item.children.length > 0) {
                    let childNames = item.children.map(item => item.name);

                    if (childNames.includes(curRouterdata.name)) {
                        this.$set(item, 'mark', true);
                    }
                }
            })
        },
        /**
         * @Description: 查看页面是否需要左右滚动
         * @Author: 武东超
         * @Date: 2024-09-11 19:55:22
         */        
        setShowEars() {
            this.$nextTick(() => {
                let clientWidth = this.$refs.scroll.$el.clientWidth;
                if (clientWidth < this.headerMenusWidth)  {
                    this.$refs['scroll'].wrap.scrollLeft = this.$refs['scroll'].wrap.scrollWidth
                    this.sLeft = this.$refs['scroll'].wrap.scrollLeft
                    this.showEars = true
                } else {
                    this.showEars = false
                }
            })
        },
        /**
         * @Description: 向左滚动
         * @Author: 武东超
         * @Date: 2024-09-11 20:03:38
         */        
        goLeft() {
            // this.$refs['scroll'].wrap.scrollLeft -= 50;
            let scrollLeft = this.$refs['scroll'].wrap.scrollLeft
            console.log(scrollLeft, this.sLeft, '---')
            if (scrollLeft <= this.sLeft) {
                this.$refs['scroll'].wrap.scrollLeft = scrollLeft - 100
            }
        },
        /**
         * @Description: 向右滚动
         * @Author: 武东超
         * @Date: 2024-09-11 20:03:51
         */        
        goRight() {
            let scrollLeft = this.$refs['scroll'].wrap.scrollLeft;
            console.log(scrollLeft, this.sLeft, '+++')

            if (scrollLeft < this.sLeft) {
                this.$refs['scroll'].wrap.scrollLeft = scrollLeft + 100
            }
        },
        headerMenuMouseenter() {
            this.hoverShow = true;
        },
        headerMenuMouseleave() {
            this.hoverShow = false;
        }
    },
    watch: {
        $route: {
            handler (newVal) {
                if (newVal.name) {
                    this.setHeaderMenuSel(newVal)
                }
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style scoped lang="scss">
.header-list {
    list-style: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: all 0.3 ease;

    /deep/.el-scrollbar__wrap {
        margin-bottom: 0px;
    }
}

.header-menus {
    height: 60px;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    .ears {
        z-index: 999999;
        position: absolute;
        width: 15px;
        height: 50px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
        img {
            width: 7px;
            height: 12px;
        }
    }
    .left {
        top: 4.25px;
        left: 0;
        padding: 13px 0px 0 4px;
    }
    .right {
        top: 4.25px;
        right: 0;
        padding: 13px 0px 0 5px;
    }
}

.header-list {
    white-space: nowrap;
    position: relative;
    box-sizing: border-box;
    padding: 0 10px;
}

.list-item {
    width: 112px;
    font-weight: 600;
    display: inline-block;
    line-height: 60px;
    height: 60px;
    text-align: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: var(--header-text);
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.list-item.current {
    color: var(--header-active);
}

.list-item:hover {
    color: var(--header-active);
}
</style>
