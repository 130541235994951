<template>
    <transition name="fade">
        <div 
            id="J_header-option" 
            class="header-options-list"
            :style="{ top: placement.top, left: placement.left}"
            data-libs="J_mouse"
            @mouseleave="handlerMouseleave"
        >
            <div class="triangle" data-libs="J_mouse"></div>
            <ul 
                class="list" 
                data-libs="J_mouse"
            >
                <li 
                    v-for="(item, index) in optionList"
                    :key="index"
                    class="item"
                    data-libs="J_mouse"
                    @click="handlerClick(item)"
                    @mouseenter.stop="handlerMouseenter(item)"
                    @mouseleave.stop="itemHandlerMouseleave"
                >
                    <img :src="itemIcon(item)" class="icon" alt="icon">
                    <span class="text">{{ item.name }}</span>
                    <div class="send-back" v-if="item.id == '2' && feedbackNum > 0">
                        <span v-if="feedbackNum <= 9">
                            {{ feedbackNum }}
                        </span>
                        <span v-else>
                            9+
                        </span>
                    </div>
                    <div v-if="item.id === '3' && dealtNum > 0" class="send-back">
                        <span v-if="dealtNum <= 9">
                            {{ dealtNum }}
                        </span>
                        <span v-else>
                            9+
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </transition>
</template>
<script>
    import Feedback from '@/xkUI/pages/Feedback/src/index.vue';
    import createdFeedback from '@/xkUI/pages/Feedback/index.js';
    import browserInfo from '@/libs/browserInfo.js';
    import { EventTracking } from '@/eventTracking/index.js';
    import browserTool from "browser-tool";
    import { getToken } from "@/libs/auth";
    import { mapState } from 'vuex';
    export default {
        name: "HeaderOptionsList",
        components: {
            Feedback
        },
        props: {
            placement: Object
        },
        data() {
            return {
                optionList: [
                    {
                        id: '1',
                        name: '帮助中心',
                        fn: 'clickHelp'
                    },
                    {
                        id: '2',
                        name: '意见反馈',
                        fn: 'clickFeedback'
                    },
                    {
                        id: '3',
                        name: '待办事项',
                        fn: 'clickPending'
                    },
                    {
                        id: '4',
                        name: 'AI助理',
                        fn: 'clickChatBot'
                    },
                    {
                        id: '5',
                        name: '在线客服',
                        fn: 'clickSupportBot'
                    }
                ],
                mouseEnterNum: 0
            }
        },
        computed: {
            ...mapState({
                dealtNum: (state) => state.dealtNum,
                originRouters: (state) => state.originRouters,
                userRouters: (state) => state.userRouters,
                schoolName: (state) => state.schoolName,
                userName: (state) => state.userName,
                userId: (state) => state.userId,
                feedbackNum: (state) => state.feedbackNum,
            }),
            isHelp(){
                let show  = false;
                if(this.originRouters && this.originRouters.length > 0){  
                    show = this.originRouters.some(item => item.name === "systemHelp");
                }
                return show
            },
            chatBotUrl() {
                return process.env.VUE_APP_XK_SERVER_CHAT_BOT_URL;
            },
            itemIcon() {
                return (item) => {
                    switch(item.id) {
                        case '1':
                            if (this.mouseEnterNum == '1') {
                                return require('@/assets/images/header/help-center-h.png')
                            }
                            return require('@/assets/images/header/help-center.png')
                        case '2':
                            if (this.mouseEnterNum == '2') {
                                return require('@/assets/images/header/feedback-h.png')
                            }
                            return require('@/assets/images/header/feedback.png')
                        case '3':
                            if (this.mouseEnterNum == '3') {
                                return require('@/assets/images/header/to-do-list-h.png')
                            }
                            return require('@/assets/images/header/to-do-list.png')
                        case '4':
                            if (this.mouseEnterNum == '4') {
                                return require('@/assets/images/header/ai-assistant-h.png')
                            }
                            return require('@/assets/images/header/ai-assistant.png')
                        case '5':
                            if (this.mouseEnterNum == '5') {
                                return require('@/assets/images/header/online-customer-service-h.png')
                            }
                            return require('@/assets/images/header/online-customer-service.png')
                        default:
                            break;
                    }
                }
            }
        },
        created() {
            this.$on('handlerGlobalClick', (e) => {
                this.handlerGlobalClick(e)
            })
            this.init()
        },
        methods: {
            /**
             * @Description: 初始化
             * @Author: 武东超
             * @Date: 2024-08-20 11:54:54
             */    
            init() {
                this.initPageData();
            },      
            /**
             * @Description: 初始化页面数据
             * @Author: 武东超
             * @Date: 2024-08-20 11:55:26
             */   
            initPageData() {
                this.checkHasHelp();
            },     
            /**
             * @Description: 是否含有帮助
             * @Author: 武东超
             * @Date: 2024-08-20 11:56:10
             */   
            checkHasHelp() {
                if (!this.isHelp) {
                    this.optionList = this.optionList.filter(item => item.id !== '1')
                }
            },  
            /**
             * @Description: 点击事件
             * @Author: 武东超
             * @Date: 2024-08-20 14:04:23
             */                     
            handlerClick(item) {
                console.log(item.fn,'item.fn=item.fn')
                if(item.fn) {
                    this[item.fn]();
                }
            },  
            /**
             * @Description: 点击帮助中心
             * @Author: 武东超
             * @Date: 2024-08-20 14:05:41
             */              
            clickHelp() {
                this.$router.push({name: "systemHelp"});
            },
            /**
             * @Description: 意见反馈
             * @Author: 武东超
             * @Date: 2024-08-20 14:16:47
             */            
            async clickFeedback() {
                this.feedback && this.feedback.close();
                this.feedback = createdFeedback(Feedback, {
                    projectId: '63803e5553e6416d79b106bf',
                    schoolName: this.schoolName,
                    userName: this.userName,
                    userRouters: this.userRouters,
                    originRouters: this.originRouters,
                    userId: this.userId
                });

                try {
                    const getBrowserInfo = browserInfo();
                    const eventTracking = new EventTracking();
                    const info = await browserTool.getInfo();
                    const { browser, browserVersion, system, systemVersion } = info;
                    let requestData = {
                        viewerIP: "",
                        platName: "校园端",
                        platType: "PC",
                        systemName: "云校园",
                        systemMark: "cloud_campus",
                        menuName: "意见反馈",
                        path: window.location.href,
                        browserName: (browser || "") + '_' + getBrowserInfo(), // 浏览器名称
                        browserVersion: browserVersion || "", // 浏览器版本
                        osName: system ? `${system} ${systemVersion}` : "", // 操作系统名称
                        currentToken: getToken(),
                        userId: this.userId,
                        userName: this.userName,
                    };
                    eventTracking.behavioralAnalysisServer(requestData)
                }catch(e) {
                    console.log('[意见反馈信息]',e)
                }
            },
            /**
             * @Description: 代办事项
             * @Author: 作者
             * @Date: 2024-08-20 14:27:41
             */            
            clickPending() {
                this.$router.push({name: "DealtList"});
            },
            /**
             * @Description: AI机器人
             * @Author: 作者
             * @Date: 2024-08-20 14:29:05
             */            
            clickChatBot() {
                let res = `https://ask.xinkaoyun.com:8888/#/login?token=${getToken()}`;
                const _window = window.open();
                if (_window) {
                    _window.location.href = res;
                } else {
                    const a = document.createElement("a");
                    a.href = res;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            },
            /**
             * @Description: 客服机器人
             * @Author: 武东超
             * @Date: 2024-08-20 14:30:12
             */            
            clickSupportBot() {
                const host = this.chatBotUrl;
                const name = this.$store.state.loginName;
                const projectId = "63803e5553e6416d79b106bf";
                this._fet("/school/login/getLogLoginToken", {
                    username: name,
                    productId: "cloud_campus",
                    projectId: "63803e5553e6416d79b106bf",
                }).then(e => {
                    const r = e.data.data;
                    window.open(`${host}/?systemMark=${r.systemMark}&projectId=${projectId}&token=${r.token}#/chat?pid=${r.projectId}`, "_blank");
                });
            },
            /**
             * @Description: 滑出
             * @Author: 武东超
             * @Date: 2024-08-20 14:04:34
             */            
            handlerMouseleave() {
                this.$eventBus.$emit('setImgRight');
                this.close();
            },
            /**
             * @Description: item滑入
             * @Author: 武东超
             * @Date: 2024-08-20 14:04:34
             */ 
            handlerMouseenter(item) {
                this.mouseEnterNum = item.id;
            },
            /**
             * @Description: item滑出
             * @Author: 武东超
             * @Date: 2024-08-20 14:04:34
             */ 
            itemHandlerMouseleave() {
                this.mouseEnterNum = 0;
            },
            handlerGlobalClick() {
                console.log('ss')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .header-options-list {
        position: fixed;
        z-index: 2;
        
        .triangle {
            position: absolute;
            top: 10px;
            left: -8px;
            width: 0;
            height: 0;
            border-color: #F8EBF3;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            border-right: 10px solid #F8EBF3;
        }
        .list {
            width: 144px;
            margin: 0;
            padding: 0 10px 0 0;
            text-align: center;
            box-sizing: border-box;
            padding: 7px;
            background: linear-gradient(-37deg, #DFDDFF, #F8EBF3);
            box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.3);
            border-radius: 6px;
            .item {
                position: relative;
                display: flex;
                align-items: center;
                height: 31px;
                line-height: 31px;
                font-family: Microsoft YaHei;
                font-weight: 500;
                font-size: 14px;
                color: #2B2F33;
                border-radius: 4px;
                padding-left: 18px;
                box-sizing: border-box;
            }
            .item:hover {
                color: #3C7FFF;
                background-color: #FFFFFF;
                font-weight: bold;
                cursor: pointer;
            }
            .icon {
                width: 14px;
            }
            .text {
                margin-left: 6px;
            }
            .send-back {
                position: absolute;
                right: 14px;
                top: 8px;
                width: 14px;
                height: 14px;
                line-height: 14px;
                text-align: center;
                font-size: 12px;
                color: #FFFFFF;
                background: #F84141;
                border-radius: 50%;
                span {
                    display: block;
                    transform: scale(0.8);
                }
            }
        }   
    }
</style>